<template>
  <div class="trainMore container">
    <div class="container-fluid">
      <NavTitle title="首页" :conton="conton" text="更多"> </NavTitle>
      <div class="clear trainMore-box">
        <div class="fl trainMore-let">
          <div
            :class="['btn-dynamic', trainMoreIdex == index ? 'active' : '']"
            v-for="(itme, index) in trainMoreList"
            :key="index"
          >
            <el-button @click="trainMoreIncident(itme, index)">
              <div>{{ itme.name }}</div>
            </el-button>
          </div>
        </div>
        <div class="fl trainMore-rig">
          <div class="list-box" v-loading="loading">
            <div
              class="list-cill clear"
              v-for="(itme, index) in list"
              :key="index"
              @click="subjectSkip(itme)"
            >
              <div class="fl trainMore-title">{{ itme.subject }}</div>
              <div class="fr" style="marginright: 65px">
                {{
                  itme.createDate | timeFormatter("{year}年{month}月{day}日")
                }}
              </div>
              <div class="fr wh">浏览量：{{ itme.scanNum }}</div>
            </div>
          </div>
          <div class="pageSize" v-if="currentTotal > 10">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="currentChange"
              :total="currentTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavTitle from '@/components/nav-title/index';
import TRAIN_MORE_LIST from '@/config/train_more';
import { informationMore } from '@/api/api';
import { timeFormatter, routerPush } from '@/utils/verify';
import list from '@/mixins/list';
export default {
  name: 'TrainMore',
  filters: { timeFormatter },
  mixins: [list],
  components: {
    NavTitle
  },
  data() {
    return {
      trainMoreList: TRAIN_MORE_LIST,
      trainMoreIdex: 0,
      type: '',
      conton: ''
    };
  },
  methods: {
    init() {
      const _this = this;
      const { type } = _this.$route.query;
      _this.type = parseInt(type);
      switch (_this.type) {
        case 1:
          _this.conton = '培训通知';
          break;

        default:
          _this.conton = '培训动态';
          break;
      }
      _this.trainMoreIdex = parseInt(type) - 1;
      _this.getInformationMore(type);
    },
    getInformationMore(type) {
      const _this = this;
      const jsonData = {
        type: String(type)
      };
      const param = _this.getParams(jsonData);
      informationMore(param).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          _this.list = list;
          _this.currentTotal = res.data.total;
          _this.loading = false;
        }
      });
    },
    trainMoreIncident(itme, index) {
      const _this = this;
      switch (index) {
        case 1:
          _this.conton = '培训动态';
          break;

        default:
          _this.conton = '培训通知';
          break;
      }
      const { type } = _this.$route.query;
      _this.replaceRouter(index + 1, type);
      _this.trainMoreIdex = index;
      if (type == index + 1) {
        return;
      }
      _this.getInformationMore(index + 1);
    },
    replaceRouter(date, parameter) {
      const _this = this;
      if (parameter == date) {
        return;
      }
      _this.$router.replace({
        path: _this.$route.path,
        query: { ..._this.$route.query, type: date }
      });
    },
    subjectSkip(e) {
      routerPush(this, '/train', { id: e.id });
    },
    loadData() {
      this.init();
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.trainMore {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #fff;
  .trainMore-box {
    box-sizing: border-box;
    width: 100%;
  }
  .trainMore-let {
    width: 148px;
    .btn-dynamic {
      width: 100%;
      height: 40px;
      margin-bottom: 30px;
      /deep/.el-button {
        width: 100%;
        border-radius: 20px;
        background: @TEXT-BG-FFFFFF;
        border: 1px solid @--color-primary;
        .fontSize(@TEXT-SIZE-16);
        color: @--color-primary;
      }
      &.active .el-button {
        border: 1px solid @--color-primary;
        background: @--color-primary;
        color: @TEXT-COLOR-FFFFFF;
      }
    }
  }
  .trainMore-rig {
    box-sizing: border-box;
    padding-left: 80px;
    width: ceil(1200px - 148px - 20px);
    .list-box {
      width: 100%;
      padding-bottom: 70px;
      box-sizing: border-box;
      .border-direction(top,1px,solid,@TEXT-BG-E6E6E6);
      .list-cill {
        box-sizing: border-box;
        padding: 0 15px;
        height: 60px;
        line-height: 60px;
        .border-direction(bottom,1px,solid,@TEXT-BG-E6E6E6);
        .fontSize(@TEXT-SIZE-16);
        cursor: pointer;
        overflow: hidden;
        .trainMore-title {
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .wh {
          width: 120px;
        }
        .fr {
          .fontSize(@TEXT-SIZE-14);
          color: @TEXT-COLOR-888888;
        }
        &:hover .fl {
          color: @--color-primary;
        }
        &:hover .fr {
          color: @--color-primary;
        }
      }
    }
    .commentBox();
  }
}
</style>

